<template>
  
    <s-crud 
    title="Registro de Descarte de Lote de Producción"
    :config="configdiscart"
    @save="save($event)"
    edit
    add
    remove   
    search-input  
    ref="crud"
    @rowSelected="rowSelected($event)"
    :filter="filter"
    >  
   <template scope="props">
           <v-row >
            <v-col cols="6" class="s-col-form" sm="4" md="8" lg="2">
             <s-text label="Lote" v-model="props.item.DisLprID"></s-text>
            </v-col>                                   
               </v-row >
               <v-row>    
              <!--    -->
                <v-container v-if="props.item != null">
                   
                           <template>
                            <v-row>
                              <v-col>
                                <s-toolbar  add remove color="primary" @add='addDetalle(props.item)'  @removed='removeloteagregado()' label="Detalle de Lotes de Producción"></s-toolbar>
                              </v-col>
                            </v-row>
                            
                          </template>   
                  <v-col cols="3" class="s-col-form">


                    <s-select-lot-production  v-model="props.item.LprID" label="Lote - N.Jabas" :estado="2" >>
                    </s-select-lot-production>  


                  </v-col>
                  <v-data-table
                            add
                            v-model="selectedLotesP"
                            :headers="headerLotesP"
                            :items="itemsLotesP"
                            item-key="LprID"
                            show-select
                            label="Edit"
                            class="elevation-1">
                          </v-data-table> 
                 </v-container>
                 <!--    -->
                 <v-col cols="6" class="s-col-form" sm="4" md="8" lg="2">
                        <s-text label="P. Neto" v-model="props.item.DisNetWeight" v-text="sumjabas" ></s-text>
                  </v-col>  
                  <v-col cols="6" class="s-col-form" sm="4" md="8" lg="2">
                        <s-text label="P.Exportación" v-model="props.item.DisWeightExp" number></s-text>
                  </v-col>      
                  <v-col cols="6" class="s-col-form" sm="4" md="8" lg="2">
                        <s-text label="P. Descarte" v-model="props.item.DisWeigthDiscart"></s-text>
                  </v-col>  
                  <v-col cols="6" class="s-col-form" sm="4" md="8" lg="2">
                        <s-text label="Tamaño de Muestra" v-model="props.item.DisSampleSize" number></s-text>
                  </v-col>   
                  </v-row>
                    <v-row>
                        <v-container v-if="props.item != null">
                          <!-- La barra de template guia de abajo -->
                           <template>
                            <v-row> 
                              <v-col>
                                <s-toolbar  add remove color="primary" @add='addDetalle(props.item)'  @removed='removeDetalleagregado()' label="Detalle del Descarte"></s-toolbar>
                              </v-col>
                            </v-row>
                             <!-- La guia de abajo -->
                          </template>                         
                          <v-col cols="12" class="s-col-form" sm="4" md="8" lg="4">
                           <s-select-definition v-model="ObjectItem"  return-object :def="1247" label="ITEM" ></s-select-definition>
                           </v-col>
                          <v-col cols="12" class="s-col-form" sm="4" md="8" lg="4">
                           <s-text label="Cantidad" v-model="quantity"> </s-text>
                          </v-col>                    
                          <v-data-table
                            add
                            v-model="selected"
                            :headers="header"
                            :items="items"
                            item-key="DetDisName"
                            show-select
                            label="Edit"
                            class="elevation-1">
                          </v-data-table>                       
                        </v-container>
                    </v-row>   
          </template>
   </s-crud>

</template>


<script>
import _service from "@/services/FreshProduction/PrfLotProductionDiscartService.js";
import SText from '../../../../components/Utils/SText.vue';
import SSelectLotProduction from '@/components/FreshProduction/LayetteIncidence/SSelectLotProduction.vue';


export default {
  name: "RegisterDiscarLotProduction",
  components: { SText,SSelectLotProduction  },
  props: {
   addNoFunction: { type: Boolean, default: false },
  },

  data: () =>({
    DisLprID: 0,
     sumjabas:0,
     quantity:0,
    //configuracion de la grilla del Detalle 
     ObjectItem:{},
     items:[],
     selected:[],
     header:[
            {text:"Id.Item", value:"DetDisName"},
            {text:"Descripción", value:"DedDescription"},
            {text:"Cantidad", value:"DetQuantity"},
            {text:"%", value:"DetPercentage"},
            ],

    ObjDetalle:{
      DetalleObj:null,
    },
    // configuracion de la grilla de lotes de produccion
  ObjectItemLotesP:{},
     itemsLotesP:[],
     selectedLotesP:[],
     headerLotesP:[
            {text:"Lote", value:"LprID"},
            {text:"Descripción", value:"DisLprID"},
            {text:"P. Neto", value:"LprNetWeight"},
          ],

    ObjDetalleLotesP:{
      DetalleObjLotesP:null,
    },    
  }),

  computed: {
        filter() {
            return{

            };
         },
   
    configdiscart() {
      return {
        model: {       
          DisLprID: "ID",
        }, 
        service: _service,
        headers:  this.$fun.getSecurity().IsLevelAdmin  
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "DisLprID", sortable: false },
              { text: "Muestra", value: "DisSampleSize", sortable: false }, 
              { text: "P. Neto", value: "DisNetWeight", sortable: false }, 
              { text: "P. Exportación", value: "DisWeightExp", sortable: false }, 
              { text: "P. Descarte", value: "DisWeigthDiscart", sortable: false },          
            ]
          : [ 
                // CABECERA EN CASO NO SE LOGEE COMO ADMIN            
              { text: "ID", value: "DisLprID", sortable: false },
              { text: "Muestra", value: "DisSampleSize", sortable: false }, 
              { text: "P. Neto", value: "DisNetWeight", sortable: false }, 
              { text: "P. Exportación", value: "DisWeightExp", sortable: false }, 
              { text: "P. Descarte", value: "DisWeigthDiscart", sortable: false },                
            ],
      };
    }, 
    
    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
         },  
  },
  

  methods: {

     removeDetalleagregado() {
      this.selected.forEach((element) => {
        this.items = this.items.filter(
          (x) => x.DetDisName != element.DetDisName
        );
      });
    },

    sumar(){    
      this.sumjabas=0;
              this.itemsLotesP.forEach((element) => {                          
              this.sumjabas = this.sumjabas + parseInt(element.LprNetWeight);              
        });           
    },

    addDetalle(item) {
      let objM = {
        DetDisName:this.ObjectItem.DedValue ,
        DedDescription: this.ObjectItem.DedDescription,
        DetQuantity: this.quantity,
        DisLprID:item.DisLprID,
        DetPercentage:this.ObjectItem.DedHelper=="Peso"? 0: (this.quantity)/item.DisSampleSize,
        SecStatus:1,
     };
     console.log(this.ObjectItem);
      let exist = false;
      this.items.forEach((element) => {
        if (this.ObjDetalle.DetDisName == element.DetDisName) {
          exist = true;
        }
      });
      if (exist == false) {
       /* if (this.tvid > 0) {
          objM.TvID = this.tvid;
        }*/
        this.items.push(objM);
      }
    },

 Initialize() {      
     
    },
    edit(item) {
      this.dialogEdit = true;
    
    },

    rowSelected(item) {
     this.dialogEdit = true;
     this.DisLprID = 0;
      if (item.length > 0) this.DisLprID = item[0].DisLprID;
      this.$emit("rowSelected", item);
      //Llenar detalles para editado
      this.items = [];
      _service
        .listDetailotdiscart(this.DisLprID,this.$fun.getUserID())
        .then((response) => {
          let data = response.data;
          data.forEach((element) => {
            let obj = {
              DetDisName:element.DetDisName ,
              DedDescription: element.DedDescription,
              DetQuantity: element.DetQuantity,
              DisLprID:element.DisLprID,
              DetPercentage:element.DetPercentage,
              SecStatus:element.SecStatus,
            };
            this.items.push(obj);
          });
        })
        .catch((err) => {
          console.error("err");
        });

      // llenar detalle de lotes de produccion
      
       _service
        .listDetaiLotProduction(this.DisLprID,this.$fun.getUserID())
        .then((response) => {
          let data = response.data;
          this.itemsLotesP=[];
          data.forEach((element) => {
            let obj = {
              LprID:element.LprID ,
              DisLprID: element.DisLprID,                       
              LprNetWeight:element.LprNetWeight,
            };
            this.itemsLotesP.push(obj);
            this.sumar();
          });
        })
        .catch((err) => {
          console.error("err");
        });

      
    },

    save(item) {
      item.DetailDiscart=this.items;
      item.SecStatus = 1;
       if (item.DisWeightExp == 0) {
        this.$fun.alert("Ingrese el peso de exportacion", "warning");
        return;
      }
      if (item.DisNetWeight == 0) {
        this.$fun.alert("Ingrese el peso neto", "warning");
        return;
      }
    if (item.DisSampleSize == 0) {
        this.$fun.alert("Ingrese el tamaño de la muestra", "warning");
        return;
      }
      item.save();
    },    
  },
  };
</script>
