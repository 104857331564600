import Service from "../Service";
import Vue from "vue";
const resource = "lotProductionDiscart/"

export default {

  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
        params: { requestID: requestID },
    });
  },   

  save(cmp, requestID) {
    return Service.post(resource + "save", cmp, {
        params: { requestID: requestID },
    });
  },
  
  listDetaiLotProduction(lotediscart,RequestID){ 
    return Service.post(resource + "listDetaiLotProduction",{},{
    params:{Lotediscart: lotediscart, RequestID: RequestID}
  });  
},

  listDetailotdiscart(lotediscart,RequestID){ 
    return Service.post(resource + "listDetailotdiscart",{},{
    params:{Lotediscart: lotediscart, RequestID: RequestID}
  });  
  
  },
  GetUrllistDetaildiscart(){
    return Vue.prototype.$http.defaults.baseURL + "/lotProductionDiscart/listDetailotdiscart";
    },
};