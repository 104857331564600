import Service from "../Service";
import Vue from "vue";
const resource = "LayIn/"

export default {


    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    GetUrllistLayette() {
        return Vue.prototype.$http.defaults.baseURL + "/LayIn/listLayette";
    },





    listLayette(RequestID) {
        return Service.post(resource + "listLayette", {}, {
            params: { RequestID: RequestID }
        });

    },


    listlotproduction(status, layID, RequestID) {
        return Service.post(resource + "listlotproduction", {}, {
            params: { Status: status, LayID: layID, RequestID: RequestID }
        });

    },
    GetUrllistlotproduction() {
        return Vue.prototype.$http.defaults.baseURL + "/LayIn/listlotproduction";
    },

};